
import Modal from '~/components/common/Modal';

export default {
  components: {
    Modal,
  },
  methods: {
    closeModal () {
      this.$emit('close-modal');
    },
  },
};


import LOCALE_DESTINATION from '~/assets/constants/LOCALE_DESTINATION_COUNTRY';
import LOCALE_ORIGIN from '~/assets/constants/LOCALE_ORIGIN_COUNTRY';
import StelaInputSelect from '~/components/common/StelaInputSelect';
import ExtraLargeShipmentModal from '~/components/modals/ExtraLargeShipmentModal';
import PLAN from '~/assets/constants/PLAN';
import { SHIPPING_METHODS_ARRAY } from '~/assets/constants/SHIPPING_METHODS.js';

export default {
  name: 'StelaPriceCalculatorPanel',
  components: {
    StelaInputSelect,
    ExtraLargeShipmentModal,
  },
  data () {
    return {
      allShippingMethods: SHIPPING_METHODS_ARRAY,
      lbsToKgDivisor: 2.2046,
      loading: false,
      destinationCountry: null,
      originCountry: null,
      weight: 0,
      kg: 0,
      height: 0,
      width: 0,
      length: 0,
      preferredCurrency: 'USD',
      deliveryType: null,
      selectedFreightOption: null,
      showExtraLargeModal: false,
      containsXlShipments: false,
      deliveryFee: {
        charge: 0,
        currency: 'USD',
        display: '0',
        mainUnit: 0,
        subUnit: 0
      },
      totalFee: {
        charge: 0,
        currency: 'USD',
        display: '0',
        mainUnit: 0,
        subUnit: 0
      },
      showExtraLargeShipmentModal: false,
    };
  },
  computed: {
    computedWeight: {
      get () {
        return this.weight;
      },
      set (currentValue) {
        this.weight = Math.abs(currentValue);
      },
    },
    freightShippingGroupOptions () {
      return this.allShippingMethods.map(frieght => ({
        label: frieght.key.replace('_', ' '),
        value: frieght.key
      }));
    },
    weightLimitConverter () {
      const weightLimits = {
        lbs: { min: 1, max: 999999999999999 },
        kg: { min: Number((1 / this.lbsToKgDivisor).toFixed(2)), max: Number((999999999999999 / this.lbsToKgDivisor).toFixed(2)) },
      };
      return weightLimits;
    },
    selectedDeliveryType () {
      return this.deliveryType?.value;
    },
    formData () {
      return {
        weight: this.weight,
        originCountry: this.originCountry,
        destinationCountry: this.destinationCountry,
        freight_mode: this.selectedFreightOption,
        deliveryType: this.deliveryType,
        containsXlShipmentst: this.containsXlShipments,
      };
    },
    supportedDestinationCountryOptions () {
      return Object.values(this.excludeFlaggedCountries(LOCALE_DESTINATION.COUNTRIES, this.featureFlaggedDestinationCountries)).map(({ alpha2, emoji, name }) => ({
        label: `${emoji} ${name}`,
        value: alpha2,
      }));
    },
    supportedOriginCountryOptions () {
      return Object.values(this.excludeFlaggedCountries(LOCALE_ORIGIN.COUNTRIES, this.featureFlaggedOriginCountries)).map(({ alpha2, emoji, name }) => ({
        label: `${emoji} ${name}`,
        value: alpha2,
      }));
    },
    showExtraLargeShipmentCheckbox () {
      return this.destinationCountry && this.destinationCountry.value === LOCALE_DESTINATION.COUNTRY.NG.alpha2;
    },
    deliveryTypes () {
      if (this.destinationCountry && this.destinationCountry.value === LOCALE_DESTINATION.COUNTRY.GH.alpha2) {
        return [
          {
            label: 'Ghana Pickup',
            value: this.DELIVERY_CHOICE_PICKUP,
          },
          {
            label: 'Home Delivery',
            value: this.DELIVERY_CHOICE_HOME,
          },
        ];
      }

      return [
        {
          label: 'Lagos Pickup',
          value: this.DELIVERY_CHOICE_PICKUP,
        },
        {
          label: 'Home delivery - Lagos',
          value: this.DELIVERY_CHOICE_HOME_LAGOS,
        },
        {
          label: 'Home delivery - Outside Lagos',
          value: this.DELIVERY_CHOICE_HOME_NON_LAGOS,
        },
      ];
    },
    getPreferredCurrencySymbol () {
      if (this.preferredCurrency === 'NGN') {
        return '₦';
      }
      if (this.preferredCurrency === 'USD') {
        return '$';
      }
      return '';
    },
    formattedTotalAmount () {
      return new Intl.NumberFormat('en-US').format(this.totalFee.mainUnit);
    },
  },
  watch: {
    destinationCountry () {
      this.deliveryType = this.deliveryTypes[0];
    },
  },
  mounted () {
    this.destinationCountry = this.supportedDestinationCountryOptions.find(({ value }) => {
      return value === LOCALE_DESTINATION.COUNTRY.NG.alpha2;
    });

    this.originCountry = this.supportedOriginCountryOptions.find(({ value }) => {
      return value === LOCALE_ORIGIN.COUNTRY.US.alpha2;
    });
  },
  created () {
    this.unwatch = this.$watch(
      'formData',

      this.debounce(() => {
        if (this.weight && this.deliveryType.label && this.deliveryType.value) {
          this.estimatePrice();
        }
      }, 1000
      )
    );
  },
  beforeDestroy () {
    this.unwatch();
  },
  methods: {
    async estimatePrice () {
      const { deliveryChoice, addressIsWithinLagos } = this.interpretDeliveryType(this.deliveryType?.value);
      const requestData = {
        pickup_country: this.destinationCountry?.value,
        origin_country: this.originCountry?.value,
        weight: Math.ceil(this.weight),
        width: this.width,
        height: this.height,
        freight_mode: this.selectedFreightOption?.value,
        length: this.length,
        plan_code: PLAN.BASIC.code,
        delivery_choice: deliveryChoice,
        origin_warehouse: 'US-TX-HOU',
        delivery_address: {
          country: this.destinationCountry?.value,
          state: addressIsWithinLagos ? 'LAGOS' : 'UNKNOWN',
          name: 'UNKNOWN',
          phone: 'UNKNOWN',
          street: 'UNKNOWN',
          city: 'UNKNOWN'
        },
      };
      if (this.containsXlShipments) {
        requestData.width = 1;
        requestData.height = 1;
        requestData.length = 1;
      }
      try {
        this.loading = true;
        const { data } = await this.$api.ordering.orders.estimate({
          data: requestData,
        });
        if (data.payload) {
          this.deliveryFee = data.payload.estimate.deliveryFee;
          this.totalFee = data.payload.estimate.total;
        }
      } catch (error) {
        this.handleServerError(error);
      } finally {
        this.loading = false;
      }
    },
    getFormattedWeight (val, unit) {
      const value = Number(val);
      const { min, max } = this.weightLimitConverter[unit];
      if (value > max) {
        return max;
      } else {
        return value >= min ? value : min;
      }
    },
    interpretDeliveryType (deliveryType) {
      const deliveryTypeMap = {
        [this.DELIVERY_CHOICE_HOME]: this.DELIVERY_CHOICE_HOME,
        [this.DELIVERY_CHOICE_PICKUP]: this.DELIVERY_CHOICE_PICKUP,
        [this.DELIVERY_CHOICE_HOME_LAGOS]: this.DELIVERY_CHOICE_HOME,
        [this.DELIVERY_CHOICE_HOME_NON_LAGOS]: this.DELIVERY_CHOICE_HOME,
      };

      const deliveryChoice = deliveryTypeMap[deliveryType];
      const addressIsWithinLagos = deliveryType === this.DELIVERY_CHOICE_HOME_LAGOS;
      return { deliveryChoice, addressIsWithinLagos };
    },
    convertWeight (value, unit) {
      const converters = {
        lbs: (weight) => {
          const formattedKg = weight && this.getFormattedWeight(weight, 'kg');
          this.kg = formattedKg;
          this.weight = formattedKg ? Number((formattedKg * this.lbsToKgDivisor).toFixed(2)) : null;
        },
        kg: (weight) => {
          const formattedLbs = weight && this.getFormattedWeight(weight, 'lbs');
          this.weight = formattedLbs;
          this.kg = formattedLbs ? Number((formattedLbs / this.lbsToKgDivisor).toFixed(2)) : null;
        },
      };
      return converters[unit](value);
    },
  },
};

export const PLAN = {
  BASIC: {
    code: 'HPL-GROUND_ZERO',
    name: 'BASIC',
  },
  PRO: {
    code: 'HPL-oA6sVL6h',
    name: 'PRO',
  },
  PREMIUM: {
    code: 'HPL-fbVGCXuE',
    name: 'PREMIUM',
  },
};

export default PLAN;
